<template lang="pug">
.jalousie
  .jalousie__item._precont: .jalousie__item-content
  .jalousie__item: .jalousie__item-content
    .jalousie__title._an._1(@click='scrollMeTo("#about")', tabindex='0') About
  .jalousie__item: .jalousie__item-content 
    router-link.jalousie__title._an._2(:to='{ name: "ListingsPage" }') Listings
    //- router-link.jalousie__link._an._3(:to='{ name: "ListingsPage", params: { for: "for_rent" } }') For rent
    router-link.jalousie__link._an._4(:to='{ name: "ListingsPage", params: { for: "for_sale" } }') For sale
    router-link.jalousie__link._an._5(:to='{ name: "SoldPage" }') Sold listings
  .jalousie__item: .jalousie__item-content 
    router-link.jalousie__title._an._6(:to='{ name: "MapPage", query: { location: 3 } }') Map view
    //- router-link.jalousie__link._an._7(:to='{ name: "MapPage", query: { location: 1 } }') USA
    //- router-link.jalousie__link._an._8(:to='{ name: "MapPage" }') Caribbean
    //- router-link.jalousie__sublink._an._9(:to='{ name: "MapPage", query: { location: 2 } }') Bahams
    router-link.jalousie__link._an._10(:to='{ name: "MapPage", query: { location: 3 } }') Turks & Caicos
    //- router-link.jalousie__sublink._an._11(:to='{ name: "MapPage", query: { location: 4 } }') Anguilla
    //- router-link.jalousie__sublink._an._12(:to='{ name: "MapPage", query: { location: 5 } }') Antigua
    //- router-link.jalousie__sublink._an._13(:to='{ name: "MapPage", query: { location: 6 } }') St. Lucia
    router-link.jalousie__link._an._13(:to='{ name: "MapPage", query: { location: 7 } }') Dominican Republic
  .jalousie__item._last: .jalousie__item-content
    .jalousie__title._an._14(@click='scrollMeTo("#contact")', tabindex='0') Contact
  SvgComponent.jalousie__close(name='close', tabindex='0', ref='jalousieСlose')
</template>

<script>
  import SvgComponent from '@/components/SvgComponent.vue';
  export default {
    components: {
      SvgComponent,
    },
    methods: {
      scrollMeTo(refName) {
        var element = document.querySelector(refName);

        function scroll() {
          setTimeout(() => {
            var element = document.querySelector(refName);
            var top = element.offsetTop;
            window.scrollTo(0, top);
          }, 100);
        }

        if (element) {
          scroll();
        } else {
          this.$router.push({ name: 'HomePage' }, scroll, scroll);
        }
      },
    },
    mounted() {
      const desktop = window.matchMedia('(min-width: 769px)').matches;
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      var menubutton = document.querySelector('.burger');
      var jalousie = document.querySelector('.jalousie');
      let tempKludgeValue =
        document.querySelector('.tempKludgeJalousie').getBoundingClientRect().width + 'px';

      document.querySelector('._precont').style.maxWidth = window.matchMedia('(max-width: 1800px)')
        .matches
        ? tempKludgeValue
        : '100px';
      if (!iOS) {
        document.querySelector('.jalousie__close').style.right = window.matchMedia(
          '(max-width: 1800px)',
        ).matches
          ? tempKludgeValue
          : '100px';
      }

      let scrollbarWidth = null;
      menubutton.onfocus = function () {
        // scrollbarWidth = window.innerWidth - document.documentElement.offsetWidth;
        // if (scrollbarWidth && desktop) {
        //   document.querySelector('.headerContainer').style.position = 'relative';
        //   document.querySelector('.headerContainer').style.right = '7.5px';
        //   jalousie.style.marginRight = '15px';
        // }
        document.querySelector('#app').style.overflowY = 'hidden';
        document.body.style.overflowY = 'hidden';

        jalousie.classList.add('_open');
        setTimeout(
          () => {
            jalousie.classList.add('_open2');
          },
          desktop ? 500 : 0,
        );
        setTimeout(
          () => {
            jalousie.classList.add('_open3');
          },
          desktop ? 1000 : 0,
        );
      };
      menubutton.onblur = function () {
        // document.querySelector('.headerContainer').style.right = 0;
        // jalousie.style.marginRight = 0;
        document.querySelector('#app').style.overflowY = 'visible';
        document.body.style.overflowY = 'visible';
        jalousie.classList.remove('_open3');

        setTimeout(
          () => {
            jalousie.classList.remove('_open2');
          },
          desktop ? 200 : 500,
        );
        setTimeout(
          () => {
            jalousie.classList.remove('_open');
          },
          desktop ? 700 : 500,
        );

        jalousie.classList.add('_closean');
        setTimeout(() => {
          jalousie.classList.remove('_closean');
        }, 500);
      };
    },
  };
</script>

<style lang="scss">
  .tempKludgeJalousie {
    grid-column: 1;
    grid-row: 1;
  }
  .jalousie {
    z-index: -10;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include tablet {
      will-change: background-color;
      transition: background-color 0.2s;
      flex-direction: column;
      justify-content: flex-start;
      padding: 32px 80px;
    }
    &__item {
      flex-grow: 1;
      transition: 0.5s transform;
      height: 100%;
      transform-origin: top left;
      position: relative;
      &:first-child {
        &:after {
          background: transparent;
        }
      }
      &:not(:last-of-type) {
        @include tablet {
          will-change: transform;
          transition: 0.2s border-bottom;
          border-bottom: 2px solid transparent;
        }
      }
      &:after {
        content: '';
        background: #ededed;
        width: 2px;
        height: 100%;
        position: absolute;
        left: 0;
        transform: scaleY(0);
        transition: 0.5s transform;
        transform-origin: top left;
        z-index: 1;
        @include tablet {
          display: none;
        }
      }
      &-content {
        background: white;
        @include tablet {
          background: transparent;
        }
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scaleX(0);
        transition-duration: 0.5s, 0.2s;
        transition-property: transform, color;
        transform-origin: top left;
        display: flex;
        flex-direction: column;
        padding-top: 200px;
        color: white;
        visibility: hidden;
        @include gap(30px, col);

        @include tablet {
          // visibility: visible;
          padding-top: 0;
          color: black !important;
          position: static;
          transform: scale(1);
          // transition-property: color, opacity;
        }
        @include phone {
          // visibility: visible;
          @include gap(16px, col);
        }
      }
      &._precont {
        @include tablet {
          display: none;
        }
      }
      @include tablet {
        height: auto;
        width: 100%;
        flex-grow: 0;
        padding: 40px 0;
      }

      @include phone {
        padding: 16px 0;
      }
      &._last {
        // background: red!important;
        margin-right: -15px;
      }
    }
    &__title {
      font-size: 36px;
      text-transform: uppercase;
      font-weight: 600;
      left: 0;
      position: relative;
      margin-left: 2px;
      cursor: pointer;
      @include tablet {
        font-size: 28px;
        line-height: 34px;
      }
      @include phone {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__link {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      padding-left: 30px;
      position: relative;
      @include tablet {
        font-size: 18px;
        line-height: 22px;
      }
      @include phone {
        font-size: 16px;
        line-height: 19px;
      }
    }
    &__sublink {
      font-size: 24px;
      line-height: 29px;
      padding-left: 70px;

      @include tablet {
        font-size: 16px;
        line-height: 19px;
      }
    }
    &._open {
      @include tablet {
        background: white;
      }
      visibility: visible;
      .jalousie__item-content {
        visibility: visible;
      }
      z-index: 4;
      .jalousie__item:after {
        transform: scaleY(1);
      }
    }
    @include tablet {
      ._an {
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: 0.3s;
        transform: translateX(100px);
        animation-fill-mode: forwards;
      }

      &._closean {
        transition: 0.5s;
        transform: translate(100%);
        ._an {
          // opacity: 0;
        }
      }
    }

    &._open2 {
      ._an {
        opacity: 1;
        @include tablet {
          @for $i from 1 through 14 {
            &._#{$i} {
              transition-delay: #{$i * 35}ms;
            }
          }
        }
        transform: none;
      }
      .jalousie__item-content {
        visibility: visible;

        transform: scaleX(1);
      }
    }
    &._open3 {
      @include tablet {
        .jalousie__item:not(:last-of-type) {
          border-bottom: 2px solid #ededed;
        }
      }
      .jalousie__item-content {
        color: black;
        &:hover .jalousie__title,
        .jalousie__sublink:hover,
        .jalousie__link:hover {
          color: $red;
        }
      }
      .jalousie__close {
        color: black;
      }
    }

    &__close {
      color: transparent;
      transition: 0.2s color;
      right: 30px;
      top: 45px;
      z-index: 1111;
      position: absolute;
      cursor: pointer;
      @include tablet {
        top: 28px;
        transform-origin: right top;
        transform: scale(0.8);
      }
    }
  }
</style>
